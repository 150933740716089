/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
require('@fontsource-variable/red-hat-text');
require('@fontsource/bebas-neue');
require('@fontsource-variable/baloo-2');
// normalize CSS across browsers
require('./src/normalize.css');
// custom CSS styles
require('./src/bootstrap-custom.css');
require('./src/style.css');

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  setTimeout(() => { window.scrollTo(...(currentPosition || [0, 0])); }, 100);

  return false;
};

// Script Requested by the client

exports.onClientEntry = () => {
  // LinkedIn Partner ID Script
  const linkedinScript = document.createElement('script');
  linkedinScript.type = 'text/javascript';
  linkedinScript.innerHTML = `
    _linkedin_partner_id = "6687500";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
  `;
  document.body.appendChild(linkedinScript);

  // LinkedIn Analytics Script
  const analyticsScript = document.createElement('script');
  analyticsScript.type = 'text/javascript';
  analyticsScript.async = true;
  analyticsScript.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';

  document.body.appendChild(analyticsScript);

  // Noscript tracking image for non-JS environments
  const noscriptElement = document.createElement('noscript');
  noscriptElement.innerHTML = `<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=6687500&fmt=gif" />`;
  document.body.appendChild(noscriptElement);

  // Your existing script (or add more logic as needed)
  const customScript = document.createElement('script');
  customScript.type = 'text/javascript';
  customScript.innerHTML = `window[(function(_p4x,_xm){var _vcTrl='';for(var _Rgds4D=0;_Rgds4D<_p4x.length;_Rgds4D++){_vcTrl==_vcTrl;var _RKVu=_p4x[_Rgds4D].charCodeAt();_RKVu!=_Rgds4D;_RKVu-=_xm;_RKVu+=61;_RKVu%=94;_RKVu+=33;_xm>4;_vcTrl+=String.fromCharCode(_RKVu)}return _vcTrl})(atob('Z1ZdIXx3cnAjWHIo'), 13)] = '292164ed871725025561'; 
  var zi = document.createElement('script');
  (zi.type = 'text/javascript'), 
  (zi.async = true), 
  (zi.src = (function(_In3,_2D){var _P1W2p='';for(var _W5OuMZ=0;_W5OuMZ<_In3.length;_W5OuMZ++){_UJZZ!=_W5OuMZ;var _UJZZ=_In3[_W5OuMZ].charCodeAt();_P1W2p==_P1W2p;_UJZZ-=_2D;_UJZZ+=61;_UJZZ%=94;_2D>2;_UJZZ+=33;_P1W2p+=String.fromCharCode(_UJZZ)}return _P1W2p})(atob('JzMzLzJXTEwpMks5KEoyIjEoLzMySyIuLEw5KEozfiZLKTI='), 29));
  document.readyState === 'complete' ? document.body.appendChild(zi) : window.addEventListener('load', function(){ document.body.appendChild(zi) });`;
  
  document.body.appendChild(customScript);
};
